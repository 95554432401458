'use strict';

import { createApp } from 'vue';

import App from './views/App.vue';

import router from './router';
import WebBuilder from './builder_frontend';

import "../public/styles/styles.css";

const app = createApp(App);

const productionTip = true;

app.config.globalProperties.$hostname_front = (productionTip) ? 'https://olese.cz' : 'http://localhost:3044';
app.config.globalProperties.$hostname_back = (productionTip) ? 'https://olese.cz' : 'http://localhost:8080';
app.config.globalProperties.$CurrentPage = 0;
app.config.globalProperties.$LoggedIn = false;
app.config.globalProperties.$Router = router;
app.config.globalProperties.$Builder = new WebBuilder(
    app.config.globalProperties.$hostname_back
    , app.config.globalProperties.$Router
);

app.use(router);
app.mount('#app');