<template>
    <footer class="c_div_flex_column_center c_div_fullwidth">
        <div class="c_div_fullwidth c_div_flex_row">
            <div class="c_div_halfwidth c_text_center">
                info@olese.cz<br />+420 723 399 475
            </div>
            <div class="c_div_halfwidth c_div_flex_row_center">
                <a class="footer_item_pic" href="https://instagram.com/olese_zs?igshid=YmMyMTA2M2Y%3D">
                    <div id="footer_item_instagram" class="footer_logo_size c_backface_visibility_hidden" />
                </a>
                <a class="footer_item_pic c_no_rpad" href="https://www.facebook.com/profile.php?id=100086220702750">
                    <div id="footer_item_facebook" class="footer_logo_size c_backface_visibility_hidden" />
                </a>
            </div>
        </div>
        <div id="footer_author" class="c_text_center c_div_fullwidth noselect">
            Made by Petr H. &#169; 2022
        </div>
        <a v-bind:href="this.$hostname_back + '/Admin'">
            <div id="footer_admin" class="c_div_fullwidth c_div_flex_row_center">
                <div id="footer_item_admin"
                    class="footer_logo_size c_backface_visibility_hidden c_cursor_pointer footer_item_admin_login" />
            </div>
        </a>
    </footer>
</template>

<script>
export default {
    mounted() { },
    components: {},
    watch: {},
    methods: {},
    computed: {}
}
</script>

<style>
footer {
    color: white;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 20px;
}

.footer_item_pic {
    padding-right: 10px;
}

#footer_item_instagram {
    -webkit-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -ms-transition: 200ms background-color-ms-filter linear;
    -o-transition: 200ms background-color linear;
    transition: 200ms background-color linear;

    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url('/public/icons/instagram.svg');
    mask-image: url('/public/icons/instagram.svg');
    background-color: white;
}

#footer_item_instagram:hover {
    background-color: magenta;
}

#footer_item_facebook {
    -webkit-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -ms-transition: 200ms background-color-ms-filter linear;
    -o-transition: 200ms background-color linear;
    transition: 200ms background-color linear;

    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url('/public/icons/facebook.svg');
    mask-image: url('/public/icons/facebook.svg');
    background-color: white;
}

#footer_item_facebook:hover {
    background-color: rgb(64, 116, 212);
}

#footer_item_admin {
    -webkit-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -moz-transition: 200ms background-color linear;
    -ms-transition: 200ms background-color-ms-filter linear;
    -o-transition: 200ms background-color linear;
    transition: 200ms background-color linear;

    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url('/public/icons/tree.svg');
    mask-image: url('/public/icons/tree.svg');
}

.footer_item_admin_login {
    background-color: white;
}

.footer_item_admin_login:hover {
    background-color: forestgreen;
}

.footer_item_admin_logout {
    background-color: darkred;
}

.footer_item_admin_logout:hover {
    background-color: white;
}

#footer_author {
    margin-top: 10px;
}

#footer_admin {
    margin-top: 10px;
    margin-bottom: 40px;
}
</style>
