<template>
    <div id="i_articles_container" class="c_content_padding">
        <div v-html="content"/>
        <div class="c_article" v-for="article in articles" v-bind:key="article.id">
            <h2 class="c_article_title" v-html="article.title" />
            <div class="c_article_content" v-html="article.content" />
            <div class="c_article_info">
                <div class="c_text_small">Autor: <span class="c_article_author c_text_small" v-html="article.author" />
                </div>
                <div class="c_text_small">Datum: <span class="c_article_date c_text_small"
                        v-html="FormatDate(article.timestamp)" /></div>
                <div class="c_text_small">Čas: <span class="c_article_time c_text_small" v-html="FormatTime(article.timestamp)" /></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            content: "",
            articles: null,
        }
    },
    mounted() {
        this.$Builder.getDataPromise().then(() => {
            for(var i = 0; i < this.$Builder.data.length; i++) {
                if(this.$Builder.data[i].link === "MayInterrestYou") {
                    this.content = this.$Builder.data[i].content;
                    document.body.style.backgroundImage = "url('" + this.$hostname_back + this.$Builder.data[i].background_image + "')";
                    break;
                }
            }
        });

        this.$Builder.getDataPromiseArticles().then(() => {
            this.ProcessArticles();
        });
    },
    methods: {
        ProcessArticles() {
            this.articles = this.$Builder.articles;

            console.log(JSON.stringify(this.articles));
        },
        FormatDate(timestamp) {
            console.log("converting: " + timestamp);
            const date = new Date(timestamp * 1000);
            const values = date.toLocaleString('cs-CZ').split(' ');
            return values[0] + values[1] + values[2];
        },
        FormatTime(timestamp) {
            const date = new Date(timestamp * 1000);
            const values = date.toLocaleString('cs-CZ').split(' ');
            return values[3];
        }
    },
}
</script>

<style scoped>
#i_articles_container {
    color: white;
    height: auto;
    margin-top: 40px;
}

.c_article_title {
    border-bottom: solid;
    border-color: white;
    border-width: 2px;
    padding-bottom: 20px;
}

.c_article {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin-bottom: 40px;
    border-radius: 10px;
}

.c_article_info {
    border-top: solid;
    border-color: white;
    border-width: 2px;
    padding-top: 20px;
}
</style>