<template>
  <div id="i_slider_photo_container" v-if="ready">
    <div id="i_slider_cpn_background_left" class="c_slider_cpn_background" v-on:click="prevSlide()">
      <div id="i_slider_cpn_left" class="c_slider_cpn" v-on:click="prevSlide()" />
    </div>
    <img id="i_slider_photo" />
    <div id="i_slider_cpn_background_right" class="c_slider_cpn_background" v-on:click="nextSlide()">
      <div id="i_slider_cpn_right" class="c_slider_cpn" v-on:click="nextSlide()" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  methods: {
    prevSlide() {
      if (this.photoList.length > 0) {
        if (this.slideIndex === 0) {
          this.slideIndex = (this.photoList.length - 1);
        } else {
          this.slideIndex--;
        }

        document.getElementById("i_slider_photo").src = this.currentSlide;
      }
    },
    nextSlide() {
      if (this.photoList.length > 0) {
        if (this.slideIndex === (this.photoList.length - 1)) {
          this.slideIndex = 0;
        } else {
          this.slideIndex++;
        }

        document.getElementById("i_slider_photo").src = this.currentSlide;
      }
    },
    WindowWidthChanged(e) {
      e;
      // document.getElementById("id_photo_slider").style.height = (document.getElementById("id_photo_slider").style.width * 0.5) + 'px';
    }
  },
  computed: {
    currentSlide: function () {
      return this.photoList[this.slideIndex];
    }
  },
  data() {
    return {
      slideIndex: 0
      , photoList: []
      , ready: false
    };
  },
  mounted() {
    window.addEventListener('resize', this.WindowWidthChanged);

    axios.get(this.$hostname_back + '/api/get/SliderData')
      .then((response) => {
        this.photoList = JSON.parse(response.data);

        for (var i = 0; i < this.photoList.length; i++) {
          this.photoList[i] = this.$hostname_back + '/' + this.photoList[i];
        }

        this.ready = true;
      })
      .catch((error) => {
        console.log(error);
        this.errorMessage = 'Could not get slider data';
        this.ready = false;
      });
  },
  unmounted() {
    window.removeEventListener('resize', this.WindowWidthChanged);
  },
  updated() {
    if (this.photoList.length > 0) {
      document.getElementById("i_slider_photo").src = this.currentSlide;
    }
  }
}
</script>

<style scoped>
#i_slider_photo {
  width: auto;
  height: 100%;
}

.c_slider_cpn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 60px;
  transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
}

.c_slider_cpn_background {
  position: relative;
  width: auto;
  height: 100%;
}

.c_slider_cpn_background:hover {
  background-color: rgba(100, 50, 0, 0.4);
  cursor: pointer;
}

.c_slider_cpn_background:focus {
  background-color: rgba(100, 50, 0, 0.4);
  cursor: pointer;
}

.c_slider_cpn_background:active {
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

#i_slider_cpn_background_left {
  left: 60px;
}

#i_slider_cpn_background_right {
  right: 60px;
}

#i_slider_cpn_left {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url('/public/icons/arrow-left-bold-svgrepo-com.svg');
  mask-image: url('/public/icons/arrow-left-bold-svgrepo-com.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: white;
}

#i_slider_cpn_left:active {
  background-color: white;
  cursor: pointer;
}

#i_slider_cpn_right {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url('/public/icons/arrow-right-bold-svgrepo-com.svg');
  mask-image: url('/public/icons/arrow-right-bold-svgrepo-com.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  background-color: white;
}

#i_slider_cpn_right:active {
  background-color: white;
  cursor: pointer;
}

.c_slider_cpn:hover {
  background-color: rgba(100, 50, 0, 0.4);
  cursor: pointer;
}

.c_slider_cpn:active {
  background-color: rgba(100, 50, 0, 0.6);
}

#i_slider_photo_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
  height: 100%;
  max-height: 300px;
  overflow: hidden;
}
</style>
    