<template>
    <div class="c_container c_content_padding">
        <div v-html="this.content"></div>
    </div>
</template>
    
<script>
export default {
    props: {
        title: String,
        content: String,
        background_image: String,
    },
    mounted() { /* TODO: Set background */
        document.body.style.backgroundImage = "url('" + this.$hostname_back + this.background_image + "')";
    },
    updated() {
        document.body.style.backgroundImage = "url('" + this.$hostname_back + this.background_image + "')";
    }
}
</script>
    
<style scoped>
.c_container {
    width: 100%;
    color: white;
    height: auto;
}
</style>