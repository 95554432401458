<template>
  <div id="i_loader_background">
    <h2>Načítání...</h2>
    <div id="i_loader" />
  </div>
  <div id="pageContainer" class="noscrollvisible c_div_flex_row_center">
    <AppHeader :menuItems="pageData" />
    <div class="c_div_flex_row_center c_div_fullwidth">
      <div class="c_div_flex_row_center c_div_fullwidth">
        <AppSlider />
      </div>
    </div>
    <router-view></router-view>
    <AppFooter />
  </div>
</template>

<script>
import { getCurrentInstance } from "vue";
import { useRouter } from "vue-router";

import AppHeader from '../components/AppHeader.vue'
import AppPage from '../components/AppPage.vue'
import AppFooter from '../components/AppFooter.vue'
import AppSlider from '../components/PhotoSlider.vue'
import AppArticles from '../components/AppArticles.vue'

export default {
  name: "App",
  setup() {
    const app = getCurrentInstance().appContext.app;
    const router = useRouter();

    const createComponent = (componentName, componentPath, data) => {
      // Check if the component has been alreadey registered
      if (!app.component(componentName)) {
        app.component(componentName, AppPage);
      }

      const newComponent = app.component(componentName);
      // Adding a new route to the new component
      data;

      router.addRoute({ path: componentPath, component: newComponent, props: { title: data.title, content: data.content, background_image: data.background_image } });

      if (data.id === 1) {
        console.log(componentPath);
        router.addRoute({ path: '/', redirect: componentPath });
        router.push(componentPath);
      }
    };
    const addComponent = (componentPath, component) => {
      console.log("Path: " + componentPath);
      router.addRoute({ path: componentPath, component: component });
    };
    return {
      createComponent,
      addComponent,
    };
  },
  components: {
    AppHeader
    , AppFooter
    , AppSlider
  },
  data() {
    return {
      SPALoaded: false,
      pageData: null,
      currentPage: 0
    }
  },
  methods: {
    ProcessContentData() {
      this.pageData = this.$Builder.data;

      for (let n = 0; n < this.pageData.length; n++) {
        if (this.pageData[n].link == 'MayInterrestYou') {
          this.addComponent("/MayInterrestYou", AppArticles);
        } else {
          this.createComponent(this.pageData[n].title, '/' + this.pageData[n].link, this.pageData[n]);
        }
      }

      for (var i = 0; i < this.pageData.length; i++) {
        document.body.style.content += "url('" + this.$hostname_back + this.pageData[i].background_image + "')";
        console.log("LOADING url('" + this.$hostname_back + this.pageData[i].background_image + "')");
      }
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        if (this.SPALoaded === false) {
          document.getElementById("pageContainer").style.display = "flex";
          document.getElementById("i_loader_background").style.display = "none";
          document.getElementById("i_loader").style.display = "none";
          this.SPALoaded = true;
        }
      }
    };

    this.$Builder.getDataPromise().then(() => {
      // TODO: resize DEBOUNCING

      this.ProcessContentData();
    });

    // this.$Router.removeRoute('about');

    // let pageContainer = document.getElementById("pageContainer");
    // pageContainer.style.paddingRight = pageContainer.offsetWidth - pageContainer.clientWidth + "px";
  }
}
</script>

<style>
img {
    max-width: 100%;
}

#pageContainer {
  display: none;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  height: 100%;
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-left: 5%;
  padding-right: 5%;
  background-color: rgba(0, 0, 0, 0.65);
  overflow: hidden;
  /* overflow-y: scroll; */
  /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
  /* So the width will be 100% + 17px */
}

#i_loader_background {
  width: 100vw;
  height: 100vh;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#i_loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgba(100, 50, 0, 1);
  border-right: 16px solid green;
  border-bottom: 16px solid rgba(100, 50, 0, 1);
  border-left: 16px solid green;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
