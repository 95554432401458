'use strict'

import axios from 'axios'

class WebBuilder {
    #host;
    #router;
    #data;
    #articles;

    constructor(host, router) {
        this.#host = host;
        this.#router = router;
    }

    async getData() {
        try {
            let res = await axios({
                url: this.#host + '/api/get/BuilderData',
                method: 'get',
                timeout: 8000,
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            if (res.status == 200) {
                // test for status you want, etc
                console.log(res.status)
            }
            // Don't forget to return something   
            return res.data
        }
        catch (err) {
            console.error(err);
        }
    }

    getDataPromise() {
        return axios({
            url: this.#host + '/api/get/BuilderData',
            method: 'get',
            timeout: 8000,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            this.#data = response.data;
        })
            .catch(err => console.error(err))
    }

    setDataPromise(json) {
        console.log("SENDING: " + JSON.stringify(json));
        return axios({
            url: this.#host + '/api/set/BuilderData',
            method: 'post',
            timeout: 8000,
            data: JSON.stringify(json),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            response;
        })
            .catch(err => console.error(err))
    }

    getDataPromiseArticles() {
        return axios({
            url: this.#host + '/api/get/Articles',
            method: 'get',
            timeout: 8000,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(response => {
            this.#articles = response.data;
        })
            .catch(err => console.error(err))
    }

    setDataPromiseArticles(json) {
        console.log("SENDING: " + JSON.stringify(json));
        return axios({
            url: this.#host + '/api/set/Articles',
            method: 'post',
            timeout: 8000,
            data: JSON.stringify(json),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            response;
        })
            .catch(err => console.error(err))
    }

    get data() {
        return this.#data;
    }

    get articles() {
        return this.#articles;
    }
}

export default WebBuilder;
