<template>
  <header>
    <nav class="noselect">
      <router-link to="/">
        <div class="c_logo c_b_circle">
          <img src="/logos/O lese-04.png" />
        </div>
      </router-link>
      <div class="c_nav_button" v-on:click="MenuToggleClicked" v-if="CompactMenuAvailable === true">
        <div class="header_menu_image c_backface_visibility_hidden" />
      </div>

      <div v-if="menuVisible === true" class="c_nav">
        <router-link class="c_nav_button" v-for="menuItem in menuItems"
          v-bind:key="menuItem.id" v-html="menuItem.menu_item" :to="'/' + menuItem.link"/>
      </div>
    </nav>
    <div class="c_about header_about_size noselect">
      <div class="c_about_item">O názorech<br />a lidech</div>
      &#8226;
      <div class="c_about_item">O slunci<br />a vodě</div>
      &#8226;
      <div class="c_about_item">O zvěři<br />a stromech</div>
      &#8226;
      <div class="c_about_item">O čase<br />a přírodě</div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      menuVisible: true,
      counter: 0,
      windowWidth: document.documentElement.clientWidth,
    };
  },
  props: {
    menuItems: Array
  },
  mounted() {
    window.addEventListener('resize', this.WindowWidthChanged);
  },
  unmounted() {
    window.removeEventListener('resize', this.WindowWidthChanged);
  },
  methods: {
    WindowWidthChanged(e) {
      e;

      this.windowWidth = document.documentElement.clientWidth;

      if (!this.CompactMenuAvailable) {
        this.menuVisible = true;
      }
    },
    MenuToggleClicked() {
      this.menuVisible = !this.menuVisible;
    }
  },
  computed: {
    CompactMenuAvailable() {
      return this.windowWidth <= 1300;
    }
  }
}
</script>

<style>


.c_logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-color: rgba(0, 0, 0, 0.6);
}

.c_logo>img {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  padding-top: 20px;
  margin: 0px;
  margin-bottom: 50px;
  height: auto;
}

nav {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.c_nav {
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.c_nav_button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  white-space: nowrap;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: all 0.2s;
  transition-timing-function: ease;
  background-color: rgba(100, 50, 0, 0.6);
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding: 0px;
  font-size: 90%;
  cursor: pointer;
  border: solid;
  border-radius: 20px;
  border-width: 1px;
  border-color: rgb(100, 50, 0);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.5);
  text-shadow: 5px 5px rgba(0, 0, 0, 0.5);
}

.c_nav_button:hover {
  text-decoration: none;
  font-size: 110%;
}

.c_about {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: lightgrey;
  text-align: center;
  font-style: italic;
  margin-top: 50px;
}

.header_menu_image {
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url('/public/icons/three-dots.svg');
  mask-image: url('/public/icons/three-dots.svg');
  mask-repeat: no-repeat;
  background-color: white;
  transition: all 0.2s;
  -o-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition-timing-function: ease;
  -o-transition-timing-function: ease;
  -moz-transition-timing-function: ease;
  -webkit-transition-timing-function: ease;
}

.c_nav_button:hover>.header_menu_image {
  transform: scale(1.2);
  transform: rotate(90deg);
  -o-transform: scale(1.2);
  -o-transform: rotate(90deg);
  -ms-transform: scale(1.2);
  -ms-transform: rotate(90deg);
  -moz-transform: scale(1.2);
  -moz-transform: rotate(90deg);
  -webkit-transform: scale(1.2);
  -webkit-transform: rotate(90deg);
}
</style>
