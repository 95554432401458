import { createRouter, createWebHashHistory } from 'vue-router';

import ViewDefault from "./components/AppDefault";

const routes = [
  { path: '/', component: ViewDefault },
  { path: '/:pathMatch(.*)*', redirect: "/", }
];

const router = createRouter(
  {
    history: createWebHashHistory()
    , routes
  }
);

export default router;